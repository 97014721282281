var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass:
          "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
      },
      [_vm._m(0)]
    ),
    !_vm.loading
      ? _c(
          "div",
          {
            staticClass:
              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
              },
              [
                _c(
                  "md-card",
                  [
                    _c(
                      "md-card-header",
                      {
                        staticClass: "md-card-header-icon md-card-header-blue",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-icon" },
                          [_c("md-icon", [_vm._v("cached")])],
                          1
                        ),
                      ]
                    ),
                    _c("md-card-content", [
                      _c(
                        "div",
                        {
                          staticClass: "md-layout",
                          staticStyle: { "text-align": "center" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                            },
                            [
                              _c("h2", [_vm._v("Planos")]),
                              _c("p", [
                                _vm._v(
                                  "Aqui você pode visualizar todos os nossos planos e os detalhes de cada um!"
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "md-layout" },
              _vm._l(_vm.allPlans, function (item, key) {
                return _c(
                  "div",
                  {
                    key: key,
                    staticClass:
                      "md-layout-item md-small-size-100 md-medium-size-33 md-large-size-33 md-xlarge-size-33",
                  },
                  [
                    _c(
                      "pricing-card",
                      { attrs: { "icon-color": "icon-info" } },
                      [
                        _c(
                          "md-icon",
                          { attrs: { slot: "icon" }, slot: "icon" },
                          [_vm._v("important_devices")]
                        ),
                        _c(
                          "h3",
                          {
                            staticClass: "title",
                            attrs: { slot: "title" },
                            slot: "title",
                          },
                          [
                            _vm._v(" " + _vm._s(item.name) + " "),
                            _c("br"),
                            _c("span", { staticClass: "price" }, [
                              _vm._v(_vm._s(_vm.formatMoneyReal(item.amount))),
                            ]),
                          ]
                        ),
                        _c(
                          "md-button",
                          {
                            staticClass: "md-info md-round",
                            attrs: { slot: "footer" },
                            on: {
                              click: function ($event) {
                                return _vm.choicePlan(item.id)
                              },
                            },
                            slot: "footer",
                          },
                          [_vm._v("Detalhes")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "spinner" }, [
      _c("div", { staticClass: "rect1" }),
      _c("div", { staticClass: "rect2" }),
      _c("div", { staticClass: "rect3" }),
      _c("div", { staticClass: "rect4" }),
      _c("div", { staticClass: "rect5" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }