<template>
    <div class="md-layout">
        <div v-show="loading" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
        </div>

        <div v-if="!loading" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>cached</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content>
                        <div class="md-layout" style="text-align:center">
                            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <h2>Planos</h2>
                                <p>Aqui você pode visualizar todos os nossos planos e os detalhes de cada um!</p>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
            <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-medium-size-33 md-large-size-33 md-xlarge-size-33" v-for="(item, key) in allPlans" :key="key">
                    <pricing-card icon-color="icon-info">
                        <md-icon slot="icon">important_devices</md-icon>
                        <h3 slot="title" class="title"> {{item.name}}
                            <br>
                            <span class="price">{{formatMoneyReal(item.amount)}}</span>
                        </h3>
                        <md-button slot="footer" class="md-info md-round" @click="choicePlan(item.id)">Detalhes</md-button>
                    </pricing-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Vue from 'vue'
    import selectRadio from '../../../utils/events/select-radio.js'
    import Swal from 'sweetalert2'
    import axios from 'axios';
    import models from '../../../routes/models.js'
    import { Dialog, Button, Tabs, TabPane, Input } from 'element-ui'
    import { StatsCard } from '@/components'
    import { PricingCard, ChartCard } from '@/components'
    import { SlideYDownTransition } from 'vue2-transitions'
    const numeral = require('numeral')
    const locale = require('numeral/locales')
    numeral.locale('pt-br')
    Vue.filter("formatMoney", function (value) {
        return numeral(value).format('$ 0,0[.]00'); // displaying other groupings/separators is possible, look at the docs
    })

    export default {
        components: {
            ChartCard,
            StatsCard,
            PricingCard,
            SlideYDownTransition,
            [Button.name]: Button,
            [Dialog.name]: Dialog,
            [Tabs.name]: Tabs,
            [TabPane.name]: TabPane,
            [Input.name]: Input
        },
        data() {
            return {
                services: JSON.parse(localStorage.getItem('radio-services')),
                plan: JSON.parse(localStorage.getItem('user')).pagarme,
                allPlans: [],
                infoPlan: [],
                loading: true
            }
        },
        methods: {
            getAllPlans(){
                axios.get(`https://api.pagar.me/1/plans?count=10&page=1&api_key=ak_test_o6pNlMnzryy2DnrpqaIkG4iqy6ex5S`).then((resp) => {
                    this.allPlans = resp.data
                    this.loading = false
                })
                .catch((e) => {
                    console.log(e)
                    Swal(
                        'Falha',
                        'Encontramos um problema ao buscar os planos, por favor, tente novamente',
                        'error'
                    )
                })
            },
            getInfoPlan(id) {
                axios.get(
                        `https://api.pagar.me/1/subscriptions?plan_id=&id=${id}&api_key=ak_test_o6pNlMnzryy2DnrpqaIkG4iqy6ex5S`
                    ).then((resp) => {
                        let auxDateCreated = new Date(resp.data[0].date_created)
                        auxDateCreated = auxDateCreated.toLocaleDateString("pt-BR")

                        let auxDateEnd = new Date(resp.data[0].current_period_end)
                        auxDateEnd = auxDateEnd.toLocaleDateString("pt-BR")

                        const dateUpdate = this.convertDateToLocalString(resp.data[0].date_updated)
                        const aux = []

                        for(let item of resp.data) {
                            if(item.status == "paid"){item.status = "Ativo"}
                            if(item.payment_method == "credit_card"){item.payment_method = "Cartão de crédito"}
                            item.date_updated = dateUpdate
                            item.date_created = auxDateCreated
                            item.date_end = auxDateEnd
                            item.amount = this.formatMoneyReal(item.plan.amount)
                            aux.push(item)
                        }
                        this.infoPlan = aux
                        this.loading = false
                    })
                    .catch((e) => {
                        console.log(e)
                        Swal(
                            'Falha',
                            'Tivemos problemas ao buscar as informações do plano, por favor tente novamente.',
                            'error'
                        )
                    })
            },
            choicePlan(type) {
                Swal.fire({
                    title: "Aguarde",
                    html: "Buscando informações...",
                    onOpen: () => {
                        Swal.showLoading()
                    }
                })
                for(let obj of this.allPlans) {
                    if(type == obj.id) {
                        models().get(`services/getServicesPlan?pagarmeId=${obj.id}`).then((resp) => {
                            sessionStorage.setItem('services-plan', JSON.stringify(resp.data))
                            sessionStorage.setItem('plans', JSON.stringify(obj))
                            Swal.close()
                            this.$router.push('upgrade-plan')
                        })
                        .catch((e) => {
                            console.log(e)
                            Swal(
                                'Falha',
                                'Não foi possível buscar as informações do plano, por favor, tente novamente.',
                                'error'
                            )
                        })
                    }
                }
            },
            convertDateToLocalString(date) {
                let newDate = new Date(date);
                let day = newDate.getDate();
                let month = newDate.getMonth() + 1;
                let fullYear = newDate.getFullYear();
                let hour = newDate.getHours();
                let minutes = newDate.getMinutes();

                if (day < 10) {
                    day = "0" + day
                }
                if (month < 10) {
                    month = "0" + month
                }
                if (hour < 10) {
                    hour = "0" + hour
                }
                if (minutes < 10) {
                    minutes = "0" + minutes
                }

                return day + "/" + month + "/" + fullYear + " " + hour + ":" + minutes;
            },
            formatMoneyReal(money) {
                let rest = money.toString();
                let pointA = 0;
                let pointB = 0;
                let sumPoints = 0;
                let format = 0;

                pointA = rest.slice(0,3);
                pointB = rest.slice(3);

                sumPoints = pointA + "." + pointB;
                format = parseInt(sumPoints).toFixed(2).split('.');
                format[0] = "R$" + format[0].split(/(?=(?:...)*$)/).join('.');
                return format.join(',');
            }
        },
        mounted() {
            if(this.plan == 0) {
                this.loading = false
            }
            if (this.plan) {
                this.getInfoPlan(this.plan)
            }
            this.getAllPlans()
        },
        watch: {
            plan(newKey) {
                this.getInfoPlan(newKey)
            }
        }
    }

</script>

<style>
    .spinner {
        margin: 100px auto;
        width: 100%;
        height: 40px;
        text-align: center;
        font-size: 10px;
    }

    .spinner>div {
        background-color: #333;
        height: 50px;
        margin-right: 5px;
        width: 6px;
        display: inline-block;
        -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
        animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .spinner .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    .spinner .rect3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }

    .spinner .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    .spinner .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }

    @-webkit-keyframes sk-stretchdelay {

        0%,
        40%,
        100% {
            -webkit-transform: scaleY(0.4)
        }

        20% {
            -webkit-transform: scaleY(1.0)
        }
    }

    @keyframes sk-stretchdelay {

        0%,
        40%,
        100% {
            transform: scaleY(0.4);
            -webkit-transform: scaleY(0.4);
        }

        20% {
            transform: scaleY(1.0);
            -webkit-transform: scaleY(1.0);
        }
    }

    .text-center {
        text-align: center;
    }

    .btn-plan:visited {
        color: #a1a1a1;
    }

    .btn-plan2:visited {
        color: #fff;
    }

    .price {
        font-size: 15px;
    }

</style>
